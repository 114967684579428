<!-- 用户上传视频列表（现运行） -->
<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>用户上传</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button icon="el-icon-view" type="primary" style="margin-right:10px;float: left;" @click="searchmore" size="small">查询条件</el-button>
      <el-button icon="el-icon-delete" style="margin:0 10px;float: left;" size="small" @click="emptysearch">清空查询条件</el-button>
      <el-input placeholder="请输入关键字" v-model="keywords" clearable @clear="clearkeywords" style="width:200px;margin-left:10px;float: left;" size="small"></el-input>
      <el-button icon="el-icon-search" type="primary" style="margin-left:10px;float: left;" @click="searchkeywors" size="small">搜索</el-button>
      <el-button v-if="page1show" style="margin-right: 10px;float: right;" type="warning" @click="edit('new', '')" size="small">添加</el-button>
    </div>
    <div v-if="hascx && page2show" style="width:100%;margin-bottom:10px;float:left;text-align:left;">
      <span style="color:#409EFF;">查询条件：</span>
      <span>{{ cxtjstr | gl(cxtjstr) }}</span>
    </div>
    <p v-if="!nopage" style="font-size:12px;text-align:left;">总计：{{keywordnum}}</p>
    <el-table :data="list" border style="width: 100%" v-loading="loading">
      <el-table-column prop="id" label="ID" width="90"></el-table-column>
      <el-table-column prop="cust_id" label="用户ID" width="90"></el-table-column>
      <el-table-column prop="cust_name" label="用户姓名" width="130"></el-table-column>
      <el-table-column prop="cust_tel" label="电话号" width="120">
        <template slot-scope="scope">
          <span>{{ telxing(scope.row.cust_tel) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="标题" prop="title" width="300"></el-table-column>
      <!-- <el-table-column label="分类" prop="content_type" width='100'>
                <template slot-scope="scope">
                    <span v-if="scope.row.type == 1">反馈视频</span>
                    <span v-else>寻医视频</span>
                </template>
            </el-table-column> -->
      <el-table-column label="疾病" prop="ill_name" width="120"></el-table-column>
      <el-table-column label="是否标记标签" prop="tag_count" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.tag_count == 0">否</span>
          <span v-else>是</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="视频标签" prop="video_id"></el-table-column> -->
      <el-table-column v-if="page2show" label="阅读数" prop="view_count" width="70"></el-table-column>
      <el-table-column v-if="page2show" label="点赞数" prop="like_count" width="70"></el-table-column>
      <el-table-column v-if="page2show" label="评论数" prop="comment_count" width="70"></el-table-column>
      <el-table-column label="审核状态" v-if="page2show" prop="is_audit" width="100">
        <template slot-scope="scope">
          <div class="opt doc">
            {{ scope.row.is_audit | istf(scope.row.is_audit) }}
            <a v-if="scope.row.is_audit == 0" size="mini" type="primary" icon="el-icon-edit" @click="changeva(scope.row, 'audit', scope.row.is_audit)">审核</a>
            <a v-else size="mini" type="primary" icon="el-icon-edit" @click="changeva(scope.row, 'audit', scope.row.is_audit)">退审</a>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="是否删除" v-if="page2show" prop="is_visible" width="100">
				<template slot-scope="scope">
					<div class="opt doc">
						{{ scope.row.is_visible | istf(scope.row.is_visible) }}
						<a v-if="scope.row.is_visible == 0" size="mini" type="primary" icon="el-icon-edit" @click="changeva(scope.row, 'visible')">是</a>
						<a v-else size="mini" type="primary" icon="el-icon-edit" @click="changeva(scope.row, 'visible')">否</a>
					</div>
				</template>
			</el-table-column> -->
      <el-table-column width="300" fixed="right">
        <template slot-scope="scope">
          <div class="opt doc">
            <el-button v-if="page1show" size="mini" type="primary" icon="el-icon-edit" @click="edit('bj', scope.row)">编辑
            </el-button>
            <el-button v-if="page1show" size="mini" type="danger" icon="el-icon-delete" @click="del(scope.row, scope.$index)">
              删除
            </el-button>
            <el-button v-if="page2show" size="mini" type="primary" icon="el-icon-edit" @click="edit('look', scope.row)">查看
            </el-button>
            <el-button v-if="page2show" size="mini" type="danger" icon="el-icon-delete" @click="changeva2(scope.row, 'visible')">
              删除
            </el-button>
            <el-button v-if="page2show" size="mini" type="warning" icon="el-icon-view" @click="lookcomment(scope.row)">查看评论
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging" v-if="nopage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>

    <!-- 评论插件 -->
    <useruploadreplay :showpl.sync="showpl" :from="from" :plid="plid"></useruploadreplay>
    <!-- 详细查询 -->
    <el-dialog title="查询条件" :visible.sync="searchmoredetail" width="50%">
      <div style="width: 100%;overflow: hidden;text-align: left;">
        <p class="searchp">
          <span>分类：</span>
          <el-select filterable v-model="videosort" clearable placeholder="请选择分类" size="small">
            <el-option v-for="item in sortoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </p>
        <p class="searchp">
          <span>类型：</span>
          <el-select filterable v-model="videotype" clearable placeholder="请选择类型" size="small">
            <el-option v-for="item in typeoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </p>
        <p class="searchp">
          <span>科室：</span>
          <el-select filterable v-model="dp" clearable placeholder="请选择科室" size="small">
            <el-option v-for="item in dpoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </p>
        <p class="searchp">
          <span>审核：</span>
          <el-select filterable v-model="audit" clearable placeholder="请选择审核状态" size="small">
            <el-option v-for="item in auditoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </p>
        <p class="searchp">
          <span>评论：</span>
          <el-select filterable v-model="comment_count" clearable placeholder="是否有评论" size="small">
            <el-option v-for="item in commentcountoptions" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </p>
        <!-- <p class="searchp">
					<span>隐藏：</span>
					<el-select filterable v-model="visible" clearable placeholder="请选择隐藏状态">
						<el-option v-for="item in visibleoptions" :key="item.id" :label="item.name" :value="item.id"> </el-option>
					</el-select>
				</p> -->
      </div>
      <el-button type="primary" style="margin-left:10px" @click="getdata(1)">查询</el-button>
    </el-dialog>
    <el-dialog title="查看详情" :visible.sync="isauditlookdetail">
      <el-form class="shstyle" style="width: 80%;text-align: left;" label-width="100px" :disabled="disabled">
        <el-form-item label="分类">
          <el-input v-model="dvideosort"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-input v-model="dvideotype"></el-input>
        </el-form-item>
        <el-form-item label="患者ID">
          <el-input v-model="cust_id"></el-input>
        </el-form-item>
        <el-form-item label="电话号">
          <el-input v-model="cust_tel"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="name"></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-input v-model="sex"></el-input>
        </el-form-item>
        <el-form-item label="年龄">
          <el-input v-model="age"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-image style="width: 100px;height: 100px;" :src="headimg"> </el-image>
        </el-form-item>
        <el-form-item label="绑定疾病">
          <el-input style="width: 300px;margin-right: 20px;" v-model="ill_name"></el-input>
        </el-form-item>
        <el-form-item label="绑定医生">
          <el-input style="width: 300px;margin-right: 20px;" v-model="search"></el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="nrtitle"></el-input>
        </el-form-item>
        <el-form-item label="封面图片">
          <!-- <upload1 :src.sync="pic" style="width:180px;float:left;"></upload1> -->
          <div style="position: relative;height: 180px;float:left;" v-if="picurl != ''">
            <el-image style="height: 100%;" :src="picurl" :preview-src-list="picsrcList">
            </el-image>
          </div>
        </el-form-item>
        <el-form-item label="内容" v-if="content_type == 1 || videotype == 1">
          <el-input type="textarea" v-model="content" :rows="5"></el-input>
        </el-form-item>
        <el-form-item label="视频地址" v-if="type == 'new' && videotype == 2">
          <el-input v-model="addurl"></el-input>
        </el-form-item>
        <el-form-item label="视频" v-if="content_type == 2">
          <el-input v-model="videourl" style="margin-bottom:10px;"></el-input>
          <video id="video1" :src="videourl" controls style="width: 80%;height:400px;"></video>
        </el-form-item>
        <el-form-item label="描述" v-if="content_type == 2 || videotype == 2">
          <el-input type="textarea" v-model="des" :rows="6"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align:center;">
        <el-button type="primary" @click="changeva3(arr)">审 核</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
var _this;
import useruploadreplay from "../components/useruploadreplay.vue";
export default {
  inject: ["reload"],
  components: {
    useruploadreplay,
  },
  data() {
    return {
      list: [],
      current: 0, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      videosort: "",
      title: "标签分类",
      type: "",
      form: {
        tag_ids: "",
        video_id: "",
      },
      sortoptions: [
        { id: 1, name: "反馈视频" },
        { id: 2, name: "寻医视频" },
      ],
      videotype: "",
      typeoptions: [
        { id: 1, name: "文章" },
        { id: 2, name: "视频" },
      ],
      showpl: false,
      from: "userlist",
      plid: "",
      visible: "", //隐藏
      visibleoptions: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      audit: "", //审核
      auditoptions: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      commentcountoptions: [
        { id: 0, name: "否" },
        { id: 1, name: "是" },
      ],
      dp: "", //科室ID
      dpoptions: [],
      //分类标签
      lables: "",
      typelable: [],
      searchmoredetail: false,
      tiaojianstr: "",
      keywords: "",
      hascx: false,
      cxtjstr: [],
      pagetype: "",
      page1show: false,
      page2show: false,
      loading: false,
      isauditlookdetail: false,
      //审核查看详情
      dvideosort: "",
      dvideotype: "",
      disabled: true,
      cust_id: "",
      cust_tel: "",
      name: "",
      sex: "",
      age: "",
      nrtitle: "",
      pic: "",
      arr: "",
      looktype: "",
      content_type: "",
      picsrcList: [],
      content: "",
      addurl: "",
      videourl: "",
      des: "",
      search: "",
      picurl: "",
      headimg: "",
      ill_name: "",
      comment_count: "", //视频是否有评论
      nopage: true, //搜索关键字没有分页
      keywordnum: "",
    };
  },
  filters: {
    istf(val) {
      let str = val ? "是" : "否";
      return str;
    },
    gl(val) {
      var arr = _this.dpoptions;
      var str = "";
      if (val[0] == 1) {
        str += "分类：反馈视频；";
      } else if (val[0] == 2) {
        str += "分类：寻医视频；";
      } else {
        str += "";
      }
      if (val[1] == 1) {
        str += "类型：文章；";
      } else if (val[1] == 2) {
        str += "类型：视频；";
      } else {
        str += "";
      }
      if (val[4] != "") {
        if (val[4] == 0) {
          str += "是否有评论：否；";
        } else {
          str += "是否有评论：是；";
        }
      }
      // if (val[4] != "") {
      // 	if (val[4] == 0) {
      // 		str += "隐藏：否；";
      // 	} else {
      // 		str += "隐藏：是；";
      // 	}
      // }
      arr.forEach((element) => {
        if (val[2] == element.id) {
          str += "科室：" + element.name + "；";
        }
      });
      return str;
    },
  },
  watch: {
    $route(o, n) {
      if (this.$route.query.type) {
        this.pagetype = this.$route.query.type;
        sessionStorage.setItem("pagetype", this.pagetype);
        this.reload();
      }
    },
  },
  created() {
    if (this.$store.state.useruploadpage != "") {
      this.current = this.$store.state.useruploadpage;
    } else {
      this.current = 1;
    }
  },
  // destroyed() {
  //   console.log("xiaohui");
  //   this.current = 1;
  //   this.$store.commit("setuseruploadpage", 1);
  // },
  mounted() {
    this.pagetype = this.$route.query.type;
    sessionStorage.setItem("pagetype", this.pagetype);
    if (this.pagetype == 1) {
      this.page1show = true;
      this.page2show = false;
    } else {
      this.page1show = false;
      this.page2show = true;
    }
    _this = this;
    this.getdplist();
    this.videosort =
      Number(sessionStorage.getItem("videosort")) == 0
        ? ""
        : Number(sessionStorage.getItem("videosort"));
    this.videotype =
      Number(sessionStorage.getItem("videotype")) == 0
        ? ""
        : Number(sessionStorage.getItem("videotype"));
    this.dp =
      Number(sessionStorage.getItem("dp")) == 0
        ? ""
        : Number(sessionStorage.getItem("dp"));
    this.audit =
      Number(sessionStorage.getItem("audit")) === 0
        ? ""
        : Number(sessionStorage.getItem("audit"));
    this.comment_count =
      Number(sessionStorage.getItem("comment_count")) === 0
        ? ""
        : Number(sessionStorage.getItem("comment_count"));
    // this.visible = Number(sessionStorage.getItem("visible")) == 0 ? "" : Number(sessionStorage.getItem("visible"));
    this.cxtjstr = [];
    this.cxtjstr.push(this.videosort);
    this.cxtjstr.push(this.videotype);
    this.cxtjstr.push(this.dp);
    this.cxtjstr.push(this.audit);
    this.cxtjstr.push(this.comment_count);
    // this.cxtjstr.push(this.visible);

    // this.getdplist1();
    if (sessionStorage.getItem("keywords")) {
      this.keywords = sessionStorage.getItem("keywords");
      this.searchkeywors();
    } else {
      if (this.$store.state.useruploadpage != "") {
        this.getdata(this.$store.state.useruploadpage);
      } else {
        this.getdata(1);
      }
    }
  },
  // destroyed() {
  //   sessionStorage.removeItem("keywords");
  // },
  methods: {
    telxing(val) {
      var str = "";
      if (val == null || val == "null" || val === "" || val == undefined) {
        str = "";
      } else {
        str = val.substring(0, 3) + "****" + val.substring(7);
      }
      return str;
    },
    searchmore() {
      this.searchmoredetail = true;
    },
    emptysearch() {
      this.videosort = "";
      this.videotype = "";
      this.dp = "";
      this.audit = "";
      this.comment_count = "";
      // this.visible = "";
      this.cxtjstr = [];
      this.hascx = false;
      this.list = [];
      this.totalCount = 0;
      sessionStorage.setItem("cxtjstr", "");
      sessionStorage.setItem("videosort", "");
      sessionStorage.setItem("videotype", "");
      sessionStorage.setItem("dp", "");
      sessionStorage.setItem("audit", "");
      sessionStorage.setItem("comment_count", "");
      // sessionStorage.setItem("visible", "");
      // this.getdata(1);
    },
    getdplist() {
      this.axios.get("/gu/get_gu_department").then((res) => {
        if (res.data.code == 0) {
          this.dpoptions = res.data.result;
        }
      });
    },
    getdplist1() {
      this.axios.get("/tag/get_gu_tag").then((res) => {
        if (res.data.code == 0) {
          this.typelable = res.data.result;
        }
      });
    },
    getdata(page) {
      this.loading = true;
      var _audit = "";
      var _visiable = "";
      if (this.audit === "") {
        _audit = "all";
      } else {
        _audit = this.audit;
      }
      // if (this.visible === "") {
      // 	_visiable = "all";
      // } else {
      // 	_visiable = this.visible;
      // }
      this.cxtjstr = [];
      //"&is_visible=" +_visiable +
      var str = "";
      if (this.comment_count != "") {
        str += "&comment_count=" + this.comment_count;
      }
      this.axios
        .get(
          "/item/get_gu_items?page=" +
            page +
            "&size=" +
            this.PageSize +
            "&type=" +
            this.videosort +
            "&content_type=" +
            this.videotype +
            "&is_visible=1" +
            "&is_audit=" +
            _audit +
            "&dp_id=" +
            this.dp +
            str
        )
        .then((res) => {
          if (res.data.code == "0") {
            this.list = res.data.result;
            this.totalCount = res.data.count;
          } else {
            this.list = [];
            this.totalCount = 0;
          }
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
        });
      this.searchmoredetail = false;
      this.hascx = true;
      this.cxtjstr.push(this.videosort);
      this.cxtjstr.push(this.videotype);
      this.cxtjstr.push(this.dp);
      this.cxtjstr.push(this.audit);
      this.cxtjstr.push(this.comment_count);
      // this.cxtjstr.push(this.visible);
      sessionStorage.setItem("cxtjstr", JSON.stringify(this.cxtjstr));
      sessionStorage.setItem("videosort", this.videosort);
      sessionStorage.setItem("videotype", this.videotype);
      sessionStorage.setItem("dp", this.dp);
      sessionStorage.setItem("audit", this.audit);
      sessionStorage.setItem("comment_count", this.comment_count);
      // sessionStorage.setItem("visible", this.visible);
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      if (this.keywords == "") {
        this.getdata(currentPage);
        this.$store.commit("setuseruploadpage", currentPage);
      }
    },
    query() {
      this.list = [];
      this.axios
        .get("/tag/get_gu_tag_videotag_by_videoid?video_id=" + this.search)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clearkeywords() {
      this.list = [];
      this.totalCount = 0;
      this.nopage = true;
      this.getdata(1);
      sessionStorage.removeItem("keywords");
    },
    searchkeywors() {
      this.loading = true;
      this.nopage = false;
      this.list = [];
      sessionStorage.setItem("keywords", this.keywords);
      this.axios
        .get("/item/search_gu_items?keyword=" + this.keywords)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
            this.loading = false;
            this.keywordnum = res.data.result.length;
            // this.totalCount = res.data.result.length;
          } else {
            this.list = [];
            console.log(res);
            this.$message({
              type: "error",
              message: "没有数据",
            });
            this.loading = false;
          }
        });
    },
    searchclear() {
      this.getdata();
    },
    //编辑
    edit(type, row) {
      this.$router.push({
        path: "/userUpload/useruploaddetail",
        query: { id: row.id, type: type },
      });
    },
    lookcomment(row) {
      this.showpl = true;
      this.plid = row.id;
    },
    //修改审核、显示隐藏状态
    changeva(row, type, is_audit) {
      var url = "",
        zd = "",
        state = "";
      var form = {};
      form.id = row.id;
      if (type == "visible") {
        form.is_visible = row.is_visible == 1 ? 0 : 1;
        url = "/item/update_gu_item_visible";
      } else {
        form.is_audit = !row.is_audit ? 1 : 0;
        url = "/item/update_gu_item_audit";
      }
      if (row.is_audit == 0) {
        this.isauditlookdetail = true;
        this.getdetail(row.id);
      } else {
        this.$confirm("此操作将退审该视频, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.shenheshipin(url, form);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    shenheshipin(url, form) {
      this.axios.post(url, this.qs.stringify(form)).then((res) => {
        this.$message({
          message: "修改审核状态成功",
          type: "success",
        });
        this.reload();
      });
    },
    changeva3(val) {
      var form = {},
        url = "";
      form.id = val.id;
      form.is_audit = !val.is_audit ? 1 : 0;
      url = "/item/update_gu_item_audit";
      this.$confirm("此操作将审核该视频, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.shenheshipin(url, form);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getdetail(id) {
      this.axios.get("/item/get_gu_item_by_id?id=" + id).then((res) => {
        if (res.data.code == 0) {
          this.arr = res.data.result;
          this.nrtitle = this.arr.title;
          this.pic = this.arr.pic;
          this.looktype = this.arr.content_type;
          this.content_type = this.arr.content_type;
          if (this.arr.content_type == 2) {
            //2视频，1文章
            this.videourl = this.arr.content;
            this.des = this.arr.des;
          } else {
            this.content = this.arr.content;
          }
          this.dvideotype = this.arr.content_type == 1 ? "文章" : "视频";
          this.dvideosort = this.arr.type == 1 ? "反馈视频" : "寻医视频"; //2寻医1反馈
          if (
            this.arr.ill_lib_id != "" &&
            this.arr.ill_lib_id != null &&
            this.arr.ill_lib_id != undefined
          ) {
            this.dp = this.arr.ill_lib_id;
          }
          this.search = this.arr.doc_name;
          this.doc_id = this.arr.doc_id;
          this.ill_name = this.arr.ill_name;
          this.age = this.arr.age;
          this.name = this.arr.name;
          this.sex = this.arr.sex;
          if (this.arr.headimg == null || this.arr.headimg == "null") {
            this.headimg = this.arr.cust_headimg;
          } else {
            this.headimg = this.arr.headimg;
          }
          this.cust_id = this.arr.cust_id;
          this.picurl = this.arr.pic;
          this.picsrcList.push(this.arr.pic);
          this.cust_tel = this.telxing(this.arr.cust_tel);
        }
      });
    },
    changeva2(row, type) {
      let url, zd, state;
      let form = {};
      form.id = row.id;
      // if (type == "visible") {
      form.is_visible = row.is_visible == 1 ? 0 : 1;
      url = "/item/update_gu_item_visible";
      // } else {
      // 	form.is_audit = !row.is_audit ? 1 : 0;
      // 	url = "/item/update_gu_item_audit";
      // }
      console.log(form);

      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios.post(url, this.qs.stringify(form)).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.reload();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //删除
    del(row, index) {
      this.$confirm("此操作将永久删除该内容, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/item/delete_gu_item", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-left: 5px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
  float: left;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.el-image-viewer__wrapper {
  z-index: 3000 !important;
}

.searchp {
  float: left;
  width: 50%;
  line-height: 40px;
}
.searchp span {
  float: left;
}
.searchp .el-select {
  width: 200px;
}
.searchp .el-select .el-input {
  width: 100%;
}
.shstyle .el-image__inner {
  width: auto;
  height: 100%;
}
</style>
